import { AnimationTriggerMetadata, animate, style, transition, trigger } from '@angular/animations';

export function FadeInOut(seconds = 0.3): AnimationTriggerMetadata {
  return trigger('fadeInOut', [
    transition(':enter', [style({ opacity: 0 }), animate(`${seconds}s ease-out`, style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate(`${seconds}s ease-in`, style({ opacity: 0 }))]),
  ]);
}

export function FadeOut(seconds = 0.3): AnimationTriggerMetadata {
  return trigger('fadeOut', [transition(':leave', [style({ opacity: 1 }), animate(`${seconds}s ease-in`, style({ opacity: 0 }))])]);
}
