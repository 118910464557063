import { JsonPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideExternalLink } from '@ng-icons/lucide';
import { watchState } from '@ngrx/signals';

import { mockPreDefinedStateList } from './mock-data/quote-draft.mocks';
import { AppConfigModule, AppContext } from '../../../app-config';
import { QuoteDraftState, QuoteDraftStore } from '../../../global-store/quote-draft';

@Component({
  selector: 'app-debug-quote-draft-state',
  imports: [AppConfigModule, ReactiveFormsModule, JsonPipe, NgIconComponent],
  providers: [provideIcons({ lucideExternalLink })],
  templateUrl: './debug-quote-draft-state.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugQuoteDraftStateComponent {
  @AppContext() private _appContext!: AppContext;

  private quoteDraftStore = inject(QuoteDraftStore);

  public notProd = this._appContext.notProd;
  public quoteDraftInjectFormControl = new FormControl<string | undefined>(undefined, Validators.required);
  public preDefinedStateFormControl = new FormControl<number | undefined>(undefined, Validators.required);
  public errorMsg: undefined | string = undefined;
  public preDefinedStateList = mockPreDefinedStateList;
  public currentStateSignal = signal<object>({});

  constructor() {
    watchState(this.quoteDraftStore, (state) => this.currentStateSignal.set(state));
  }

  public injectQuoteDraft() {
    const quoteDraftValue = this.quoteDraftInjectFormControl.value;
    try {
      if (this.quoteDraftInjectFormControl.valid) {
        const newState = JSON.parse(quoteDraftValue!) as unknown as Partial<QuoteDraftState>;
        this.quoteDraftStore.patchStore(newState);
      }
    } catch {
      this.errorMsg = 'Quote Draft JSON is not valid';
    }
  }

  public applyStateScenario() {
    const newStateIndex = this.preDefinedStateFormControl.value;
    if (newStateIndex != null && newStateIndex >= 0 && newStateIndex < this.preDefinedStateList.length) {
      const newState = this.preDefinedStateList[newStateIndex]?.json;
      if (newState) {
        this.quoteDraftInjectFormControl.setValue(JSON.stringify(newState, null, 2));
      }
    } else {
      this.errorMsg = 'Scenario is not defined';
    }
  }

  public copyDraftTokenToClipboard() {
    const draftToken = this.quoteDraftStore.draftToken();
    if (draftToken) {
      navigator.clipboard.writeText(draftToken);
    }
  }
}
