<div data-testid="footer-review-component" class="block md:hidden">
  <div class="container gap-300 p-300">
    <div class="flex flex-col items-center gap-200 sm:flex-row">
      <div role="status" aria-live="polite" class="min-h-[2.5rem] w-full sm:min-h-fit sm:w-auto">
        @if (!isPolicyLoading() && (isLoadingActive$ | async) === false) {
          <div class="flex gap-300 font-bold md:items-center">
            <button
              data-testid="price-breakdown-trigger"
              (click)="togglePriceBreakdown()"
              class="flex gap-100 self-start py-100 text-left outline-none focus:ring-2 focus:ring-primary"
              aria-haspopup="dialog"
            >
              <div class="flex flex-wrap items-baseline gap-100">
                <p class="fs-body-md" id="premium-label">Annual Premium + Fees*</p>
                <svg
                  class="h-[1em] w-[1em] self-center"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  aria-hidden="true"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M12 8v4M12 16h.01" />
                </svg>
              </div>
            </button>

            <span aria-labelledby="premium-label" class="ml-auto py-100" data-testid="review-footer-premium">
              {{ policyPremiumSignal() | currency: 'USD' : 'symbol' : '1.0-0' }}
            </span>
          </div>
        } @else {
          <div class="flex h-full items-center gap-200 py-100">
            <app-inline-loading-spinner class="text-2xl text-primary" />
            <span class="fs-body-md font-bold">Recalculating your premium...</span>
          </div>
        }
      </div>

      <nav class="ml-auto flex w-full flex-col flex-nowrap justify-center space-y-300 sm:w-auto sm:flex-row sm:justify-end sm:space-x-300 sm:space-y-0" aria-label="Quote actions">
        <button data-testid="print-save-button" (click)="handlePrintOrSaveQuote()" type="button" class="button button--md button--block button--secondary w-full sm:w-auto">Print/save quote</button>
        <a [href]="redirectTo()" (click)="trackBuyThisPolicyClick()" data-testid="mobile-submit-button" class="button button--md button--block button--primary w-full sm:w-auto">
          Proceed to Checkout
        </a>
      </nav>
    </div>
  </div>
</div>
