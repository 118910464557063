import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideExternalLink } from '@ng-icons/lucide';
import { SESSION_STORAGE } from '@ng-web-apis/common';

import { AppConfigModule, AppConfigService, AppContext } from '../../../app-config';

@Component({
  selector: 'app-debug-api-root',
  imports: [AppConfigModule, ReactiveFormsModule, CommonModule, NgIconComponent],
  providers: [provideIcons({ lucideExternalLink })],
  template: `
    @if (notProd) {
      <!-- @todo - add "copy token" button when QuoteDraftStore context is resolved -->
      <h4>
        <a class="link text-link" href="https://kininsurance.atlassian.net/wiki/spaces/AAR/pages/1137278977/Quoting+UI+Debugger+Guide#Basic-Tab-(default)" target="_blank"
          >What is on this tab?<ng-icon name="lucideExternalLink" size="1em" class="ml-1 align-super" aria-hidden="true"></ng-icon
        ></a>
      </h4>
      <div class="card mt-4">
        <pre class="m-b-100"><code class="text-xs">{{ currentContextSignal()| json }}</code></pre>

        <dl class="my-500">
          <dt class="text">Current QuotingV1 API root URL:</dt>
          <dd>
            <pre><code class="text-sm" data-testid="current-api-root">{{ currentContextSignal()?.apiRoot?.quoteV1 }}</code></pre>
          </dd>
        </dl>

        <label class="select">
          <span class="select__label">API Root (Kinspector)</span>
          <input [formControl]="apiRootControl" class="input__control" type="text" placeholder="API Root URL" data-testid="enter-backend-api" />
        </label>
        <div class="form-help">Fully qualified Ex: "https://rift-pr-1234-app.dev.kin.co/api/v1" **Do NOT include an ending "/".</div>

        <button (click)="updateApiRootURL()" [disabled]="!this.apiRootControl.valid" class="button button--secondary button--sm mt-100" data-testid="update-root-api">Update API Root</button>
      </div>
    }
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugApiRootComponent {
  @AppContext() private _appContext!: AppContext;
  public sessionStorage = inject(SESSION_STORAGE);
  public appConfigService = inject(AppConfigService);
  private originalContext = this.appConfigService.appContext;
  public currentContextSignal = signal(this.appConfigService.appContext);

  public notProd = this._appContext.notProd;

  public apiRootControl = new FormControl<string>('', Validators.required);

  public updateApiRootURL() {
    if (this.apiRootControl.valid && !!this.apiRootControl.value) {
      this.sessionStorage.setItem('updatedApiRoot', this.apiRootControl.value);

      const newContext = { ...this.originalContext, apiRoot: { ...this.originalContext?.apiRoot, quoteV1: this.apiRootControl.value } };

      this.appConfigService.overrideContext(newContext as unknown as AppContext);
      this.currentContextSignal.set(newContext as unknown as AppContext);
      this.apiRootControl.setValue(this.apiRootControl.value);
    }
  }
}
