<div class="app">
  @if (notProd) {
    <kin-non-prod-banner></kin-non-prod-banner>
  }
  <app-header></app-header>

  <div class="content" [attr.data-loading]="isLoading">
    <main class="app-main">
      <router-outlet></router-outlet>
    </main>

    <section class="legal-links fs-body-sm container mt-1000 flex w-full flex-col items-start gap-400 p-300 text-neutral-800 md:mt-400 md:flex-row md:items-center print:hidden">
      <span>&copy;2016-{{ currentYear }} Kin Insurance Technology Hub, LLC.</span>
      <ul class="flex flex-col gap-300 md:ml-auto md:flex-row md:items-center">
        @if (notProd) {
          <li>
            <a class="link hover:cursor-pointer" (click)="toggleDebugTray()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-bug"
                data-testid="debugger"
              >
                <path d="m8 2 1.88 1.88" />
                <path d="M14.12 3.88 16 2" />
                <path d="M9 7.13v-1a3.003 3.003 0 1 1 6 0v1" />
                <path d="M12 20c-3.3 0-6-2.7-6-6v-3a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v3c0 3.3-2.7 6-6 6" />
                <path d="M12 20v-9" />
                <path d="M6.53 9C4.6 8.8 3 7.1 3 5" />
                <path d="M6 13H2" />
                <path d="M3 21c0-2.1 1.7-3.9 3.8-4" />
                <path d="M20.97 5c0 2.1-1.6 3.8-3.5 4" />
                <path d="M22 13h-4" />
                <path d="M17.2 17c2.1.1 3.8 1.9 3.8 4" />
              </svg>
            </a>
          </li>
        }
        <li>
          <a href="https://www.kin.com/terms/" target="_blank" rel="noopener" class="fs-body-sm text-neutral-800 underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary"
            >Terms of Service</a
          >
        </li>
        <li>
          <a href="https://www.kin.com/privacy/" target="_blank" rel="noopener" class="fs-body-sm text-neutral-800 underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary"
            >Privacy Policy</a
          >
        </li>
        <li>
          <a href="https://www.kin.com/privacy/sharing" target="_blank" rel="noopener" class="fs-body-sm text-neutral-800 underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary"
            >Do Not Sell My Info</a
          >
        </li>
        <li>
          <a href="https://www.kin.com/accessibility/" target="_blank" rel="noopener" class="fs-body-sm text-neutral-800 underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary"
            >Accessibility</a
          >
        </li>
      </ul>
    </section>

    @if (isReviewPage()) {
      <footer class="sticky bottom-0 flex bg-neutral-white print:hidden" data-testid="footer-component">
        <app-footer-review></app-footer-review>
      </footer>
    }

    <!-- Application Level Loading Overlay Screen -->
    <app-global-loading-container></app-global-loading-container>

    <!-- Application Level Contact Us Dialog -->
    <app-global-contact-dialog></app-global-contact-dialog>
  </div>
</div>

<app-debug-tray [show]="showDebugTray"></app-debug-tray>
