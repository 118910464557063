import { CUSTOM_ELEMENTS_SCHEMA, Component, inject } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucidePhone } from '@ng-icons/lucide';

import '@kin/web-components/dist/external/kin-dialog/kin-dialog';
import { ContactDialogService } from '../../../create-quote/services/contact-dialog/contact-dialog.service';

@Component({
  selector: 'app-header',
  imports: [NgIconComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './header.component.html',
  providers: [provideIcons({ lucidePhone })],
})
export class HeaderComponent {
  contactDialogService = inject(ContactDialogService);
}
