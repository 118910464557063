import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';
import { Observable } from 'rxjs';

import { GuidService } from 'app/common/services/guid/guid.service';

import { AppContext } from '../../../app-config';
import { QuoteDraftStore } from '../../../global-store/quote-draft';

/**
 * Legacy (v1) Quote API Interceptor
 * used to attach the auth header to the request
 */
@Injectable()
export class LegacyQuotesApiInterceptor implements HttpInterceptor {
  // get context from the App Context decorator
  @AppContext() private appContext!: AppContext;
  // inject the store
  store = inject(QuoteDraftStore);
  logger = inject(NgKLoggerService);
  guidService = inject(GuidService);

  /**
   * Interceptor for adding authorization and tracking headers
   */
  intercept(orgRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const urlRoots = [this.appContext.apiRoot.quoteV1]; // ensure we only add headers for v1 API request
    const urlsToSkip: string[] = [`${urlRoots[0]}/marketing_leads_for_new_states`, `${urlRoots[0]}/intro_data`, `${urlRoots[0]}/click_leads`]; // exclude the address request since that is also where we get the JWT
    const optionalUrlsToSkip = [`${urlRoots[0]}/addresses`]; // if there is a token, include it, otherwise it is OK sending without one

    const shouldAddToken: boolean = LegacyQuotesApiInterceptor.checkUrl(urlRoots, urlsToSkip, orgRequest.url);
    const isOptional: boolean = LegacyQuotesApiInterceptor.isOptionalUrl(urlRoots, optionalUrlsToSkip, orgRequest.url);

    // only add headers for selected API requests patterns
    if (shouldAddToken) {
      // get the auth token
      const authToken = this.store.draftToken();
      // if there is a token, use it, otherwise do not append the header and log an error
      if (authToken) {
        // create the new request and add auth and debug headers
        const updatedRequest = LegacyQuotesApiInterceptor.addAuthHeader(authToken, orgRequest, this.guidService);
        return next.handle(updatedRequest);
      }
      if (!isOptional) {
        // log error around attempt to make a request that had a bad or missing JWT
        this.logger.event({
          name: 'token-missing',
          metaData: {
            message: `token missing for request: ${orgRequest.url}`,
            request: orgRequest,
          },
        });
      }
    }

    // did not meet criteria to attach header, return the original request unmodified
    return next.handle(orgRequest);
  }

  /**
   * Clones the passed HTTP Req and adds auth and debug headers
   */
  static addAuthHeader(token: string, orgRequest: HttpRequest<unknown>, guidService: GuidService): HttpRequest<unknown> {
    return orgRequest.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'Session-Debug-Header': guidService.guid,
      },
    });
  }

  /**
   * ensures we only add headers for urls we care about
   */
  static checkUrl(rootUrls: string[], urlsToSkip: string[], reqUrl: string): boolean {
    // loop over root urls
    const matchedRootUrls = rootUrls.filter((rootUrl) => reqUrl.includes(rootUrl));
    const matchedSkipUrls = urlsToSkip.includes(reqUrl);
    if (matchedRootUrls.length > 0 && !matchedSkipUrls) {
      return true;
    }
    return false;
  }

  /**
   * checks if a URL is in the optional list
   */
  static isOptionalUrl(rootUrls: string[], optionalUrls: string[], reqUrl: string): boolean {
    const matchedRootUrls = rootUrls.filter((rootUrl) => reqUrl.includes(rootUrl));
    return matchedRootUrls.length > 0 && optionalUrls.includes(reqUrl);
  }
}
