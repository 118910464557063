import { __decorate } from "tslib";
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import baseStyles from '../styles/base.styles.js';
import componentStyles from './kin-loading-indicator.css.js';
/**
 * @property [variant=primary] - The color variant of the loading indicator.
 * @cssprop [--kin-loading-indicator-animation-draw-duration=2000ms] - duration of the draw animation.
 * @cssprop [--kin-loading-indicator-animation-iteration-count=infinite] - number of times the animation should repeat.
 * @cssprop [--kin-loading-indicator-small-size=3rem] - Size of the small spinner.
 * @cssprop [--kin-loading-indicator-medium-size=8rem] - Size of the medium spinner.
 * @cssprop [--kin-loading-indicator-large-size=14.5rem] - Size of the large spinner.
 * @cssprop [--kin-loading-indicator-fill-color=var(--color-neutral-900)] - Fill color of the spinner.
 */
let KinLoadingIndicator = class KinLoadingIndicator extends LitElement {
  constructor() {
    super(...arguments);
    this.variant = 'primary';
    this.size = 'medium';
  }
  render() {
    return html`<svg
      viewBox="0 0 238 238"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class=${classMap({
      primary: this.variant === 'primary',
      secondary: this.variant === 'secondary',
      small: this.size === 'small',
      medium: this.size === 'medium',
      large: this.size === 'large'
    })}
      style=""
    >
      <path fill="var(--kin-loading-indicator-fill-color)" d="M0,119A119,119 0,1,1 238,119A119,119 0,1,1 0,119"></path>
      <path
        d="M141.5 186.5V126.5C141.5 124.511 140.71 122.603 139.303 121.197C137.897 119.79 135.989 119 134 119H104C102.011 119 100.103 119.79 98.6967 121.197C97.2902 122.603 96.5 124.511 96.5 126.5V186.5"
        stroke="var(--color-primary)"
        stroke-width="10"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="stroke-1"
      ></path>
      <path
        d="M51.5 104C51.4995 101.818 51.975 99.6622 52.8934 97.6829C53.8118 95.7036 55.151 93.9485 56.8175 92.54L109.317 47.5475C112.025 45.2593 115.455 44.0039 119 44.0039C122.545 44.0039 125.975 45.2593 128.683 47.5475L181.183 92.54C182.849 93.9485 184.188 95.7036 185.107 97.6829C186.025 99.6622 186.501 101.818 186.5 104V171.5C186.5 175.478 184.92 179.294 182.107 182.107C179.294 184.92 175.478 186.5 171.5 186.5H66.5C62.5218 186.5 58.7064 184.92 55.8934 182.107C53.0804 179.294 51.5 175.478 51.5 171.5V104Z"
        stroke="var(--color-primary)"
        stroke-width="10"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="stroke-2"
      ></path>
    </svg>`;
  }
};
KinLoadingIndicator.styles = [baseStyles, componentStyles];
__decorate([property()], KinLoadingIndicator.prototype, "variant", void 0);
__decorate([property()], KinLoadingIndicator.prototype, "size", void 0);
KinLoadingIndicator = __decorate([customElement('kin-loading-indicator')], KinLoadingIndicator);
export default KinLoadingIndicator;
