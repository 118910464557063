import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';

import '@kin/web-components/dist/external/kin-dialog/kin-dialog';
import { ContactDialogService } from '../../../create-quote/services/contact-dialog/contact-dialog.service';
import { PHONE_HOURS, PHONE_NUMBERS } from '../../constants/contact-constants';
import { PhonePipe } from '../../pipes/phone-number/phone.pipe';

@Component({
  selector: 'app-global-contact-dialog',
  imports: [PhonePipe],
  template: `
    <kin-dialog [open]="isOpen()" (kin-dialog-hide)="close()" (kin-dialog-show)="open()" scrollBackdrop>
      <header slot="header">
        <h3 class="h3 h3 flex items-center gap-200">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="h-[1em] w-[1em]" viewBox="0 0 22 22" aria-hidden="true">
            <path
              fill="var(--color-primary)"
              stroke="var(--color-neutral-900)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 15.92v3a1.998 1.998 0 0 1-2.18 2 19.791 19.791 0 0 1-8.63-3.07 
              19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 3.11 1h3a2 
              2 0 0 1 2 1.72c.127.96.361 1.903.7 2.81a2 2 0 0 1-.45 2.11L7.09 8.91a16
               16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45c.907.339 1.85.574 2.81.7A2 
               2 0 0 1 21 15.92Z"
            />
          </svg>
          Contact us
        </h3>
      </header>

      <p class="mb-400">
        Speak with a licensed agent
        <span data-testid="phone-number-header-modal" class="mx-1 font-bold">{{ phoneNumber | phone }}</span>
      </p>

      <dl class="grid grid-cols-[auto_1fr] space-x-200">
        <dt class="text-right font-bold">Mon - Fri</dt>
        <dd class="text-left">
          <time>{{ hours.salesMF }}</time>
        </dd>
        <dt class="text-right font-bold">Sat</dt>
        <dd class="text-left">
          <time>{{ hours.salesSat }}</time>
        </dd>
      </dl>

      <footer class="mt-300 block sm:hidden">
        <a href="tel:{{ phoneNumber }}" class="button button--small button--secondary w-full justify-center" data-testid="mobile-call-button">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="button__prefix h-[1em] w-[1em]" viewBox="0 0 22 22" aria-hidden="true">
            <path
              fill="transparent"
              stroke="var(--color-neutral-900)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 15.92v3a1.998 1.998 0 0 1-2.18 2 19.791 19.791 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 
              3.11 1h3a2 2 0 0 1 2 1.72c.127.96.361 1.903.7 2.81a2 2 0 0 1-.45 2.11L7.09 8.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45c.907.339 
              1.85.574 2.81.7A2 2 0 0 1 21 15.92Z"
            />
          </svg>
          <span data-testid="contact-dialog-call-now">Call Now</span>
        </a>
      </footer>
    </kin-dialog>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContactDialogComponent {
  contactDialogService = inject(ContactDialogService);
  isOpen = computed(() => this.contactDialogService.isOpen());
  phoneNumber = PHONE_NUMBERS.sales;
  hours = PHONE_HOURS;

  protected close() {
    this.contactDialogService.close();
  }
  protected open() {
    this.contactDialogService.open();
  }
}
