@if (notProd) {
  <h4>
    <a class="link text-link" href="https://kininsurance.atlassian.net/wiki/spaces/AAR/pages/1137278977/Quoting+UI+Debugger+Guide#Token" target="_blank"
      >What is on this tab?<ng-icon name="lucideExternalLink" size="1em" class="ml-1 align-super" aria-hidden="true"></ng-icon
    ></a>
  </h4>
  <h4 class="mt-4 pb-200">Token</h4>
  <div class="card mt-4">
    <label class="textarea">
      <span class="textarea__label">Enter Resume Token</span>
      <textarea [formControl]="tokenFormControl" class="textarea__control" placeholder="CIR Token" rows="3"></textarea>
    </label>

    <div class="form-help">JWT from the API, we store it in the store under "draftToken"</div>

    @if (errorMsg) {
      <div class="form-help form-help--invalid">{{ errorMsg }}</div>
    }

    @if (tokenDetailsSignal(); as details) {
      <div class="form-help">{{ details }}</div>
    }

    <button (click)="processToken()" [disabled]="!this.tokenFormControl.valid" class="button button--secondary button--sm mt-100">Process Token</button>
  </div>
}
