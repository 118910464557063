import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { NgKLoggerService } from '@kin/ngk-logger';

import { decodeCirJwt } from 'app/common/services/legacy/create-cir/utils/decode-cir-jwt';
import { isCirJwtExpired } from 'app/common/services/legacy/create-cir/utils/is-jwt-cir-expired';

@Injectable({
  providedIn: 'root',
})
export class ResumeGuard implements CanActivate {
  constructor(
    private router: Router,
    private logger: NgKLoggerService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    // Get the string which is the JWT and encoded CustomerInputResponse ID from query param named 'token' through activatedRoute
    const jwtString = route.queryParams['token'];

    // Decode the 'jwtString' to separate the JWT from the encoded CustomerInputResponse ID
    const decodedToken = decodeCirJwt(jwtString);

    // If `decodedToken` is invalid or not present, redirect to start page
    if (!decodedToken) {
      this.logger.error({ message: `Invalid jwtString found when accessing '/resume' page. Rerouting user to '/quote/start' page.`, context: 'ResumeGuard', priority: 'P3' });
      this.router.navigate(['/quote/start']);
      return false;
    }

    const isExpired = isCirJwtExpired(decodedToken);

    if (isExpired) {
      this.logger.event({
        name: "ResumeGuard redirecting user to '/resume-expired-token' page because token is expired",
        metaData: { customerInputResponseID: decodedToken.data.customer_response_id },
      });
      this.router.navigate(['/resume-expired-token']);
      return false;
    }

    return true;
  }
}
