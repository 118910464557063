@if (notProd) {
  <div class="p-300">
    <h4>
      <a class="link text-link" href="https://kininsurance.atlassian.net/wiki/spaces/AAR/pages/1137278977/Quoting+UI+Debugger+Guide#Quote-State" target="_blank"
        >What is on this tab?<ng-icon name="lucideExternalLink" size="1em" class="ml-1 align-super" aria-hidden="true"></ng-icon
      ></a>
    </h4>
    <h4 class="mt-4 pb-200">Current Quote State</h4>
    <div class="card bg-gray-100">
      <pre><code class="text-xs text-wrap break-words">{{ currentStateSignal() | json }}</code></pre>
    </div>
    <button (click)="copyDraftTokenToClipboard()" class="button button--secondary button--sm mt-200" data-testid="copy-draft-token-button">Copy Draft Token</button>
  </div>
  <div class="p-300">
    <div class="card">
      <label class="select">
        <span class="select__label">Predefined Scenario</span>
        <select class="select__control" [formControl]="preDefinedStateFormControl">
          @for (stateScenario of preDefinedStateList; track i; let i = $index) {
            <option [value]="i">{{ stateScenario.label }}</option>
          }
        </select>
      </label>
      <button (click)="applyStateScenario()" class="button button--secondary button--sm mb-300 mt-100">Apply Scenario To Inject Input</button>

      <label class="textarea">
        <span class="textarea__label">Inject Quote Draft State</span>
        <textarea [formControl]="quoteDraftInjectFormControl" class="textarea__control" placeholder="Quote Draft JSON" rows="15"></textarea>
      </label>

      <div class="form-help">
        Needs to be a valid Quote Draft State (<a class="link" href="https://github.com/kin/quoting-ui/blob/main/src/app/global-store/quote-draft/quote-draft.model.ts" target="_blank" ref="noopener"
          >model</a
        >)
      </div>

      @if (errorMsg) {
        <div class="form-help form-help--invalid">{{ errorMsg }}</div>
      }

      <button (click)="injectQuoteDraft()" [disabled]="!this.quoteDraftInjectFormControl.valid" class="button button--secondary button--sm mt-100">Inject Quote Draft State</button>
    </div>
  </div>
}
