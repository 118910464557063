import { JsonPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideExternalLink } from '@ng-icons/lucide';
import { watchState } from '@ngrx/signals';

import { createPolicy } from 'app/common/services/legacy/policies/policies.factory';

import { AppConfigModule, AppContext } from '../../../app-config';
import { PolicyStore } from '../../services/legacy/policies/policies.store';
import '@kin/web-components/dist/external/kin-accordion/kin-accordion';

@Component({
  selector: 'app-debug-policy-state',
  imports: [AppConfigModule, ReactiveFormsModule, JsonPipe, NgIconComponent],
  providers: [provideIcons({ lucideExternalLink })],
  template: `
    @if (notProd) {
      <div class="p-300">
        <h4>
          <a class="link text-link" href="https://kininsurance.atlassian.net/wiki/spaces/AAR/pages/1137278977/Quoting+UI+Debugger+Guide#Policy-State" target="_blank"
            >What is on this tab?<ng-icon name="lucideExternalLink" size="1em" class="ml-1 align-super" aria-hidden="true"></ng-icon
          ></a>
        </h4>
        <kin-accordion class="mt-4">
          <h4 slot="label">Current Policy State</h4>
          <div class="card bg-gray-100">
            <pre><code class="text-xs">{{ currentStateSignal() | json }}</code></pre>
          </div>
        </kin-accordion>
      </div>
      <div class="p-300">
        <div class="card">
          <label class="textarea">
            <span class="textarea__label">Inject Policy State</span>
            <textarea [formControl]="policyStateInjectFormControl" class="textarea__control" placeholder="Policy State JSON" rows="15"> </textarea>
          </label>
          @if (errorMsg) {
            <div class="form-help form-help--invalid">{{ errorMsg }}</div>
          }
          <button (click)="injectPolicyState()" class="button button--secondary button--sm mt-100">Inject State</button>
        </div>
      </div>
    }
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugPolicyStateComponent {
  private policyStore = inject(PolicyStore);

  @AppContext() private _appContext!: AppContext;
  public notProd = this._appContext.notProd;
  public errorMsg: undefined | string = undefined;
  public currentStateSignal = signal<object>({});
  initialState = {
    data: createPolicy(),
    status: 'success',
    error: null,
  };
  public policyStateInjectFormControl = new FormControl<string | undefined>(JSON.stringify(this.initialState, null, 2), Validators.required);

  constructor() {
    watchState(this.policyStore, () => {
      this.currentStateSignal.set({
        data: this.policyStore.data(),
        status: this.policyStore.status(),
        error: this.policyStore.error(),
      });
    });
  }

  public injectPolicyState() {
    const policyStateValue = this.policyStateInjectFormControl.value;
    try {
      if (this.policyStateInjectFormControl.valid && policyStateValue) {
        const newState = JSON.parse(policyStateValue);
        if (newState.data) this.policyStore.setData(newState.data);
        if (newState.status) this.policyStore.updateStatus(newState.status);
        if (newState.error) this.policyStore.setError(newState.error);
        this.errorMsg = undefined;
      }
    } catch {
      this.errorMsg = 'Policy State JSON is not valid';
    }
  }
}
