import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';

import { AppLoaderService } from 'app/app-global-loader/service/app-loader.service';
import { GoogleTagManagerService, SegmentEvent } from 'app/common/services/analytics/google-tagmanager/gtm.service';
import { PolicyStore } from 'app/common/services/legacy/policies/policies.store';
import { SplitIOService } from 'app/common/services/splitIO/splitIO.service';
import { InlineLoadingSpinnerComponent } from 'app/create-quote/quote-review/components/inline-loading-spinner.component';
import { showPriceBreakdown } from 'app/create-quote/quote-review/price-breakdown-tooltip-signal';

@Component({
  selector: 'app-footer-review',
  imports: [InlineLoadingSpinnerComponent, CurrencyPipe, AsyncPipe],
  templateUrl: './footer-review.component.html',
  styles: [
    `
      :host {
        box-shadow: var(--shadow-md);
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterReviewComponent {
  private splitIOService = inject(SplitIOService);
  private gtmService = inject(GoogleTagManagerService);
  private loaderService = inject(AppLoaderService);
  public isLoadingActive$ = this.loaderService.isActive$;
  public isPriceBreakdownOpen = showPriceBreakdown;

  readonly policyStore = inject(PolicyStore);

  public policy = this.policyStore.data;
  public redirectTo = computed(() => this.policy().redirectTo ?? '/resume');

  public isPolicyLoading = computed(() => this.policyStore.status() === 'loading');

  public policyAddressSignal = computed(() => this.policy().address);
  public policyPremiumSignal = computed(() => this.policy().premium);

  public trackBuyThisPolicyClick() {
    this.splitIOService.trackEvent('anonymous', 'click', 0, {
      element_clicked: 'button',
      element_text: 'Buy this policy',
    });

    this.gtmService.sendDataLayerEventWithQuoteData(SegmentEvent.QUOTE_SUBMITTED);
  }

  public handlePrintOrSaveQuote() {
    window.print();
  }

  public togglePriceBreakdown() {
    showPriceBreakdown.set(!this.isPriceBreakdownOpen());
  }
}
