import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideExternalLink } from '@ng-icons/lucide';
import { format } from 'date-fns';

import { AppConfigModule, AppContext } from '../../../app-config';
import { AppLoaderService } from '../../../app-global-loader/service/app-loader.service';
import { QuoteDraftEntryService } from '../../../create-quote/services/entry/quote-draft-entry.service';
import { decodeCirJwt } from '../../services/legacy/create-cir/utils/decode-cir-jwt';
import { isCirJwtValid } from '../../services/legacy/create-cir/utils/is-jwt-cir-valid';

@Component({
  selector: 'app-debug-resume',
  imports: [AppConfigModule, ReactiveFormsModule, NgIconComponent],
  providers: [QuoteDraftEntryService, provideIcons({ lucideExternalLink })],
  templateUrl: './debug-resume.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugResumeComponent {
  private entryService = inject(QuoteDraftEntryService);
  private loaderService = inject(AppLoaderService);
  private router = inject(Router);
  @AppContext() private _appContext!: AppContext;
  public notProd = this._appContext.notProd;

  public errorMsg!: string;
  public tokenDetailsSignal = signal('');

  // controls
  public tokenFormControl = new FormControl<string | undefined>(undefined, Validators.required);

  constructor() {
    effect(() => {
      this.loaderService.switch(this.entryService.isLoadingSignal());
    });
    effect(() => {
      const nextRoute = this.entryService.entryRouteSignal();
      if (nextRoute) {
        const { route } = nextRoute;
        const path = nextRoute.type === 'form' ? ['quote/legacy', route] : [route];
        this.router.navigate(path);
      }
    });
  }

  public processToken() {
    const token = this.tokenFormControl.value;
    if (token) {
      const tokenDetails = { ...{ formattedExp: '' }, ...(decodeCirJwt(token) ?? {}) };

      if (tokenDetails && tokenDetails.exp) {
        tokenDetails.formattedExp = format(new Date(tokenDetails.exp * 1000), 'Pp (zzzz)');
        this.tokenDetailsSignal.set(JSON.stringify(tokenDetails));
      }

      if (isCirJwtValid(token)) {
        this.entryService.restoreQuoteDraftEntry(token);
      } else {
        this.errorMsg = 'token is expired or not valid';
      }
    }
  }
}
