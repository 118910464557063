@if (notProd) {
  @if (readonly) {
    <h4>
      <a class="link text-link" href="https://kininsurance.atlassian.net/wiki/spaces/AAR/pages/1137278977/Quoting+UI+Debugger+Guide#User-State" target="_blank"
        >What is on this tab?<ng-icon name="lucideExternalLink" size="1em" class="ml-1 align-super" aria-hidden="true"></ng-icon
      ></a>
    </h4>
    <h4 class="mt-4 pb-200">User State</h4>
    <div class="card bg-gray-100">
      <pre><code class="text-xs">{{ currentStateSignal() | json }}</code></pre>
    </div>
  } @else {
    <div class="card">
      <label class="textarea">
        <span class="textarea__label">Inject User State</span>
        <textarea [formControl]="userDetailsInjectFormControl" class="textarea__control" placeholder="User JSON" rows="15"></textarea>
      </label>

      <div class="form-help">
        Needs to be a valid User State (<a class="link" href="https://github.com/kin/quoting-ui/blob/main/src/app/global-store/user/user.model.ts" target="_blank" ref="noopener">model</a>)
      </div>

      @if (errorMsg) {
        <div class="form-help form-help--invalid">{{ errorMsg }}</div>
      }

      <button (click)="injectUserDetails()" [disabled]="!this.userDetailsInjectFormControl" class="button button--secondary button--sm mt-100">Inject User State</button>
    </div>
  }
}
