import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, inject, provideAppInitializer, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { NgKLoggerService } from '@kin/ngk-logger';
import { SESSION_STORAGE, WINDOW } from '@ng-web-apis/common';

import { appInitializerFactory } from './app-config';
import { AppConfigService } from './app-config/app-config.service';
import { routes } from './app.routes';
import { LegacyQuotesApiInterceptor } from './common/interceptors/legacy-quotes-api/legacy-quotes-api-interceptor.service';
import { FullstoryService } from './common/services/fullstory/fullstory.service';
import { GuidService } from './common/services/guid/guid.service';
import { SplitIOService } from './common/services/splitIO/splitIO.service';

export const appConfig: ApplicationConfig = {
  providers: [
    AppConfigService,
    GuidService,
    NgKLoggerService,
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding(), withInMemoryScrolling({ scrollPositionRestoration: 'top' })),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: LegacyQuotesApiInterceptor, multi: true },
    provideAppInitializer(() => {
      const initializerFn = appInitializerFactory(
        inject(AppConfigService),
        inject(WINDOW),
        inject(NgKLoggerService),
        inject(FullstoryService),
        inject(GuidService),
        inject(SplitIOService),
        inject(SESSION_STORAGE),
      );
      return initializerFn();
    }),
    provideAnimationsAsync(),
  ],
};
