import { CUSTOM_ELEMENTS_SCHEMA, Component, DestroyRef, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import '@kin/web-components/dist/external/kin-non-prod-banner/kin-non-prod-banner';
import { Subscription, filter } from 'rxjs';

import { AppConfigModule, AppContext } from './app-config';
import { AppGlobalLoadingContainerComponent } from './app-global-loader/app-global-loader.component';
import { AppLoaderService } from './app-global-loader/service/app-loader.service';
import { ContactDialogComponent } from './common/components/contact-dialog/contact-dialog.component';
import { FooterReviewComponent } from './common/components/footer/footer-review/footer-review.component';
import { HeaderComponent } from './common/components/header/header.component';
import { DebugTrayComponent } from './common/debug/debug-tray.component';
import { GoogleTagManagerService } from './common/services/analytics/google-tagmanager/gtm.service';
import { ContactDialogService } from './create-quote/services/contact-dialog/contact-dialog.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, AppConfigModule, DebugTrayComponent, AppGlobalLoadingContainerComponent, ContactDialogComponent, FooterReviewComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppComponent implements OnInit, OnDestroy {
  @AppContext() private _appContext!: AppContext;
  private router = inject(Router);
  private gtmService = inject(GoogleTagManagerService);

  protected readonly currentYear: number = new Date().getFullYear();
  private routerSubscription: Subscription = new Subscription();
  public notProd = this._appContext.notProd;
  public showDebugTray = false;
  public loadingService = inject(AppLoaderService);
  private destroyRef = inject(DestroyRef);
  public contactDialogService = inject(ContactDialogService);

  public isLoading = false;
  public isReviewPage = signal(false);

  ngOnInit() {
    this.gtmService.init();

    this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.isReviewPage.set(this.router.url.includes('/quote/review'));
    });

    this.loadingService.isActive$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isActive) => {
      if (isActive) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  public toggleDebugTray() {
    this.showDebugTray = !this.showDebugTray;
  }
}
