/**
 * Global Application Loader
 *
 * This is a container component that connects the service and presentation layers
 * It is not meant to be re-useable
 */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, tap } from 'rxjs';

import { AppLoaderService } from './service/app-loader.service';
import { LoadingOverlayComponent } from '../common/components';
import { AppLoadingContent } from './service/app-loader-content';

@Component({
  selector: 'app-global-loading-container',
  imports: [CommonModule, LoadingOverlayComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <app-loading-overlay
    [title]="(content$ | async)?.title"
    [message]="(content$ | async)?.message"
    [showTrustPilot]="!!(content$ | async)?.showTrustPilot"
    #loadingOverlay
  ></app-loading-overlay>`,
})
export class AppGlobalLoadingContainerComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private loadingOverlay = viewChild.required(LoadingOverlayComponent);
  private loaderService = inject(AppLoaderService);

  private delayTimeMs = 2000; // 1 second
  private hasDelay = false;
  public content$: Observable<AppLoadingContent> = this.loaderService.content$.pipe(
    tap((content) => {
      this.hasDelay = !!content.delay;
    }),
  );

  ngOnInit() {
    // listen to the loading service
    this.loaderService.isActive$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isActive) => {
      if (isActive) {
        this.turnOnLoader();
      } else if (this.hasDelay) {
        setTimeout(() => this.turnOffLoader(), this.delayTimeMs);
      } else {
        this.turnOffLoader();
      }
    });
  }

  private turnOnLoader() {
    this.loadingOverlay().on();
  }

  private turnOffLoader() {
    this.loadingOverlay().off();
  }
}
