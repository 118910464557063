import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideExternalLink } from '@ng-icons/lucide';

import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options/quote-form-options.store';

import { mockFormOptions } from './mock-form-options';
import { AppConfigModule, AppContext } from '../../../app-config';

@Component({
  selector: 'app-debug-form-options',
  imports: [AppConfigModule, NgIconComponent],
  providers: [provideIcons({ lucideExternalLink })],
  template: `
    @if (notProd) {
      <h4 class="mt-4">
        <a class="link text-link" href="https://kininsurance.atlassian.net/wiki/spaces/AAR/pages/1137278977/Quoting+UI+Debugger+Guide#Form-Options" target="_blank"
          >What is on this tab?<ng-icon name="lucideExternalLink" size="1em" class="ml-1 align-super" aria-hidden="true"></ng-icon
        ></a>
      </h4>
      <div class="card mt-4">
        <button (click)="loadOptions()" class="button button--secondary button--sm mt-100">Load Default Form Options</button>
      </div>
    }
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugFormOptionsComponent {
  public logger = inject(NgKLoggerService);
  public quoteFormOptionsStore = inject(QuoteFormOptionsStore);

  @AppContext() private _appContext!: AppContext;
  public notProd = this._appContext.notProd;

  public loadOptions() {
    this.quoteFormOptionsStore.reset(mockFormOptions);
  }
}
